import React, {Component} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const NavList = styled.ul`
    margin-top: 0px;
    width: 25%;
    margin-left:65%;
    margin-right: 10%;
    display: flex;
    align-self: center;
    margin-bottom: 0px;
    justify-content: flex-end;
`
const NavListItem = styled.li`
    list-style: none;
    display: inline;
    align-self: center;
    margin-left: 5%;
`
const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    :hover{
        color: #BEE9E8;
    }
    ::-moz-focus-inner {border:0;}
    :focus{outline: none;}
    font-family: 'PT Sans', sans-serif;
`
const Nav = styled.div`
    height: 4vh;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    background-color: #1B4965;
`
class Navigation extends Component {
    render(){
        return(
            <Nav>
                <NavList>
                    <NavListItem>
                        <NavLink to="/">Home</NavLink>
                    </NavListItem>
                    <NavListItem>
                        <NavLink to="/blog">Blog</NavLink>
                    </NavListItem>
                    <NavListItem>
                        <NavLink to="/resume">Resume</NavLink>
                    </NavListItem>
                    <NavListItem>
                        <NavLink to="/contactme">Contact Me</NavLink>
                    </NavListItem>
                </NavList>
            </Nav>
        )}
}
export default Navigation
